import React from 'react';
import './App.css';
import SignIn from './components/SignIn'; // Import the SignIn component

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Remove logo and default content, replace with Sign-In form */}
        <SignIn />
      </header>
    </div>
  );
}

export default App;
